<template>
    <div id="users">
        <el-breadcrumb separator="/" style="padding-left:10px;padding-bottom:10px;font-size:12px;">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>工厂管理</el-breadcrumb-item>
            <el-breadcrumb-item>工厂管理</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 用户列表卡片区 -->
        <el-card class="box-card">
            <el-form :inline="true" ref="form" :model="queryMap" label-width="70px" size="small">
                <el-form-item label="工厂名称">
                    <el-input v-model="queryMap.name" placeholder="请输入工厂名称" clearable></el-input>
                </el-form-item>
                <el-form-item label="联系电话">
                    <el-input v-model="queryMap.phone" placeholder="请输入联系电话" clearable></el-input>
                </el-form-item>
                <el-form-item label="地址">
                    <el-input v-model="queryMap.address" placeholder="请输入地址" clearable></el-input>
                </el-form-item>
                <el-form-item style="margin-left:50px;">
                    <el-button type="warning" @click="reset" icon="el-icon-refresh">重置</el-button>
                    <el-button type="primary" @click="getFactoryList" icon="el-icon-search">查询</el-button>
                    <el-button type="success" icon="el-icon-plus" @click="add">添加</el-button>
                </el-form-item>
            </el-form>
            <!-- 表格区域 -->
            <el-table v-loading="loading" size="small" :data="factoryList" border style="width: 100%;" height="600">
                <el-table-column label="No" width="50">
                    <template slot-scope="scope">
                        {{ scope.$index + (queryMap.currentPage - 1) * queryMap.pageSize + 1 }}
                    </template>
                </el-table-column>
                <el-table-column prop="name" width="200" show-overflow-tooltip label="工厂名称"></el-table-column>
                <el-table-column prop="username" width="150" show-overflow-tooltip label="用户"></el-table-column>
                <el-table-column prop="phone" label="联系电话" width="100"></el-table-column>
                <el-table-column prop="address" width="350" show-overflow-tooltip label="地址"></el-table-column>
                <el-table-column prop="createTime" label="创建时间" width="150"></el-table-column>
                <el-table-column label="操作" width="300">
                    <template slot-scope="scope">
                        <el-button size="small" type="primary" icon="el-icon-edit-outline"
                                   @click="factoryDetail(scope.row.id)">详细
                        </el-button>
                        <el-button size="small" type="primary" icon="el-icon-edit-outline"
                                   @click="edit(scope.row.id)">编辑
                        </el-button>
                        <el-button type="danger" size="small" icon="el-icon-delete"
                                   @click="del(scope.row.id)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination
                    style="margin-top:10px;"
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="queryMap.currentPage"
                    :page-sizes="[10, 20, 30]"
                    :page-size="queryMap.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
            ></el-pagination>

            <!-- 添加对话框 -->
            <el-dialog class="class_dialog_hospital" :title="dialogTitle" @close="closeDialog"
                       :visible.sync="addDialogVisible" width="50%;">
                <!-- 表单 -->
                <el-card class="box-card">
                    <span>
                        <el-form :model="addForm" :label-position="labelPosition" :rules="addFormRules" ref="addFormRef"
                                 label-width="80px">
                            <el-row>
                              <el-col :span="10">
                                <div class="grid-content bg-purple">
                                  <el-form-item label="工厂名称" prop="name">
                                    <el-input v-model="addForm.name" maxlength="20"></el-input>
                                  </el-form-item>
                                </div>
                              </el-col>
                            </el-row>
                            <el-row>
                              <el-col :span="10">
                                <div class="grid-content bg-purple">
                                  <el-form-item v-if="dialogType==='edit' " label="用户名：">
                                    <span v-if="dialogType==='edit'">{{addForm.username}}</span> &nbsp;&nbsp;
                                    <el-button size="small" type="primary" @click="updatePassword"
                                               v-if="dialogType==='edit' && passwordType!=1">修改密码</el-button>
                                    <el-button size="small" type="primary" @click="closeType"
                                               v-if="passwordType===1">取消</el-button>
                                  </el-form-item>
                                </div>
                              </el-col>
                            </el-row>
                            <el-row>
                              <el-col :span="10">
                                <div class="grid-content bg-purple">
                                  <el-form-item label="用户" prop="username" v-if="dialogType==='add'">
                                     <el-input v-model="addForm.username" maxlength="50"></el-input>
                                  </el-form-item>
                                </div>
                              </el-col>
                            </el-row>
                            <el-row>
                             <el-col :span="10">
                               <div class="grid-content bg-purple">
                                 <el-form-item label="密码" prop="password" v-if="dialogType==='add'|| passwordType==1">
                                   <el-input v-model="addForm.password" show-password maxlength="50"></el-input>
                                 </el-form-item>
                               </div>
                             </el-col>
                            </el-row>
                            <el-row>
                             <el-col :span="10">
                               <div class="grid-content bg-purple">
                                 <el-form-item label="确认密码" prop="repassword"
                                               v-if="  dialogType==='add'|| passwordType==1">
                                   <el-input v-model="addForm.repassword" show-password maxlength="50"></el-input>
                                 </el-form-item>
                               </div>
                             </el-col>
                            </el-row>
                            <el-row>
                              <el-col :span="10">
                                <div class="grid-content bg-purple">
                                  <el-form-item label="联系电话" prop="phone">
                                    <el-input v-model="addForm.phone" type="text"
                                              onkeyup="value=value.replace(/[^\d]/g,'')"
                                              maxlength="11"></el-input>
                                  </el-form-item>
                                </div>
                              </el-col>
                            </el-row>
                            <el-row>
                               <el-form-item label="地址" prop="address">
                                <el-input v-model="addForm.address" maxlength="110"></el-input>
                               </el-form-item>
                            </el-row>
                            <el-form-item label="简介" prop="description">
                             <el-input type="textarea" v-model="addForm.description" maxlength="500"
                                       :autosize="{ minRows: 5, maxRows: 10}"></el-input>
                            </el-form-item>
                        </el-form>
                    </span>
                </el-card>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="addDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="confirm" :disabled="btnDisabled">确 定</el-button>
                </span>
            </el-dialog>

            <!-- 添加对话框 -->
            <el-dialog class="class_dialog_hospital" :title="detailDialogTitle" :visible.sync="detaliDialogVisible"
                       width="100%;">
                <el-card class="box-card">
                    <el-form :model="addForm" :label-position="labelPosition" ref="addFormRef" label-width="140px">
                        <el-form-item label="工厂名称:">
                            <span>{{addForm.name}}</span>
                        </el-form-item>
                        <el-form-item label="用户名:">
                            <span>{{addForm.username}}</span>
                        </el-form-item>
                        <el-form-item label="联系电话:">
                            <span>{{addForm.phone}}</span>
                        </el-form-item>
                        <el-form-item label="地址:">
                            <span>{{addForm.address}}</span>
                        </el-form-item>
                        <el-form-item label="简介:">
                            <div v-html="addForm.description"></div>
                        </el-form-item>
                    </el-form>
                </el-card>
            </el-dialog>
        </el-card>
    </div>
</template>
<script>
    export default {
        data() {
            const checkPassword = (rule, value, callback) => {
                const passwordReg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/;
                if (!value) {
                    return callback(new Error("密码不能为空"));
                }
                if (value.length < 6 || value.length > 12) {
                    return callback(new Error("密码长度在6到12个字符"));
                }
                setTimeout(() => {
                    if (passwordReg.test(value)) {
                        callback();
                    } else {
                        callback(new Error("密码必须包含字母和数字的组合"));
                    }
                }, 100);
            };
            const checkRePassword = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error("确认密码不能为空"));
                }
                if (value.length < 6 || value.length > 12) {
                    return callback(new Error("密码长度在6到12个字符"));
                }
                setTimeout(() => {
                    if (this.addForm.password == value) {
                        callback();
                    } else {
                        callback(new Error("两次密码输入不一致，请确认"));
                    }
                }, 100);
            };
            const checkUser = (rule, value, callback) => {
                for (let i = 0; i < this.factoryList.length; i++) {
                    if (this.factoryList[i].username == value) {
                        callback(new Error("用户名重复请从新输入"));
                    }
                }
            };
            return {
                detailDialogTitle: '',
                detaliDialogVisible: false,
                btnDisabled: false,
                loading: true,
                total: 0,
                addDialogVisible: false, //添加对话框,
                //查询对象
                queryMap: {
                    currentPage: 1,
                    pageSize: 10,
                },
                factoryList: [],
                labelPosition: "right",
                dialogTitle: "",
                dialogType: "",
                addForm: {}, //添加表单
                addFormRules: {
                    name: [
                        {required: true, message: "请输入工厂名称", trigger: "blur"},
                    ],
                    phone: [
                        {required: true, message: "请输入手机号", trigger: "blur"},
                        {min: 11, max: 11, message: "请输入11位手机号", trigger: "blur"}
                    ],
                    address: [
                        {required: true, message: "请输入地址", trigger: "blur"},
                    ],
                    userId: [
                        {required: true, message: "请选择系统用户", trigger: "blur"},
                    ],
                    username: [
                        {required: true, message: "", validator: checkUser, trigger: "blur"},
                        {required: true, message: "请输入用户", trigger: "blur"},
                        {min: 3, max: 10, message: "长度在 3 到 10 个字符", trigger: "blur"}

                    ],
                    password: [
                        {required: true, message: "", validator: checkPassword, trigger: "blur"},
                        {min: 6, max: 12, message: "长度在 6 到 12 个字符", trigger: "blur"}
                    ],
                    repassword: [
                        {required: true, message: "", validator: checkRePassword, trigger: "blur"}
                    ],
                }, //添加表单验证规则
                users: [],
                createType: null,
                passwordType: null
            };
        },
        methods: {

            /**
             * 重置
             */
            reset() {
                this.queryMap = {
                    name: "",
                    phone: "",
                    address: "",
                    currentPage: 1,
                    pageSize: 10,

                };
                this.getFactoryList();
            },

            /**
             * 显示密码框
             */
            updatePassword() {
                this.passwordType = 1
            },

            /**
             * 关闭密码框
             */
            closeType() {
                this.passwordType = 0
            },

            /**
             * 加载工厂列表
             */
            async getFactoryList() {
                const {data: res} = await this.$http.get("factory/factorys", {
                    params: this.queryMap
                });
                if (!res.success) {
                    return this.$message.error("获取工厂列表失败:" + res.data.errorMsg);
                }
                this.total = res.data.total;
                this.factoryList = res.data.list;
            },

            /**
             * 删除工厂
             */
            async del(id) {
                const res = await this.$confirm(
                    "此操作将永久删除该工厂, 是否继续?",
                    "提示",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                    }
                )
                if (res === "confirm") {
                    const {data: res} = await this.$http.delete("factory/factory/" + id);
                    if (res.success) {
                        this.$notify.success({
                            title: '操作成功',
                            message: '工厂删除成功',
                        });
                        await this.getFactoryList();
                    } else {
                        this.$message.error(res.data.errorMsg);
                    }
                }
            },

            /**
             * 操作
             */
            confirm() {
                if (this.dialogType == "add") {
                    this.addFactory();
                } else {
                    this.updateFactory();
                }
            },

            /**
             * 添加工厂弹框
             */
            async add() {
                this.addForm = {};
                this.dialogType = "add";
                this.dialogTitle = "添加工厂"
                this.addDialogVisible = true;
            },

            /**
             * 添加工厂
             */
            async addFactory() {
                this.$refs.addFormRef.validate(async valid => {
                    if (!valid) {
                        return;
                    } else {
                        this.btnDisabled = true;
                        const {data: res} = await this.$http.post("factory/factory", this.addForm);
                        this.btnDisabled = false;
                        if (res.success) {
                            this.$notify.success({
                                title: '操作成功',
                                message: '添加成功',
                            });
                            this.addForm = {};
                            this.getFactoryList();
                        } else {
                            return this.$message.error(res.data != null ? res.data : res.data.errorMsg);
                        }
                        this.addDialogVisible = false;
                    }
                });
            },

            /**
             * 修改工厂
             */
            async updateFactory() {
                this.$refs.addFormRef.validate(async valid => {
                    if (!valid) {
                        return;
                    } else {
                        if (this.passwordType == 0) {
                            this.addForm.password = null
                            this.addForm.username = null
                        }
                        const {data: res} = await this.$http.put("factory/factory/" + this.addForm.id, this.addForm);
                        if (res.success) {
                            this.$message.success("编辑成功");
                            this.addDialogVisible = false;
                            this.passwordType = 0
                            this.getFactoryList();
                        } else {
                            this.$message.error(res.data != null ? res.data : res.data.errorMsg);
                        }

                    }
                })
            },

            /**
             * 工厂信息回显
             */
            async edit(id) {

                this.dialogType = "edit";
                this.addDialogVisible = true;
                this.dialogTitle = "编辑工厂"
                this.passwordType = 0
                const {data: res} = await this.$http.get("factory/factory/" + id);
                this.addForm = res.data;
                this.addForm.password = null
            },

            /**
             * 工厂信息详细
             */
            async factoryDetail(id) {
                this.detaliDialogVisible = true
                this.detailDialogTitle = "详细"
                const {data: res} = await this.$http.get("factory/factory/" + id);
                this.addForm = res.data;
            },

            /**
             *  改变页码
             */
            handleSizeChange(newSize) {
                this.queryMap.pageSize = newSize;
                this.getFactoryList();
            },

            /**
             * 翻页
             */
            handleCurrentChange(current) {
                this.queryMap.currentPage = current;
                this.getFactoryList();
            },

            /**
             * 关闭添加弹出框
             */
            closeDialog() {
                this.$refs.addFormRef.clearValidate();
                this.addForm = {};
                this.createType = null
            },

        },
        created() {
            this.getFactoryList();
            setTimeout(() => {
                this.loading = false;
            }, 500);
        }
    };
</script>
<style>
    .set-content {
        cursor: default;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
</style>
